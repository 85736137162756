//花名册管理
import  request  from "@/utils/request";

//学校花名册列表
export function getSchoolList (parameter){
    return request({
      url:'/api/student-roster/getSchoolList',
      method:'post',
      data:parameter
  })
}

//上传花名册
export function uploadRoster (parameter,onUploadProgress){
  return request({
    headers:{'Content-Type': 'multipart/form-data'},
    onUploadProgress,
    url:'/api/student-roster/uploadRoster',
    method:'post',
    data:parameter
  
})
}


    //年级列表
    export function getGradeList (parameter){
        return request({
          url:'/api/student-roster/getGradeList',
          method:'post',
          data:parameter
      })
    }

    //获取年级学生列表
    export function getStudentList (parameter){
      return request({
        url:'/api/student-roster/getStudentListBySchool',
        method:'post',
        data:parameter
    })
  }

  //获取班级学生列表
  export function getClassList (parameter){
    return request({
      url:'/api/student-roster/getClassList',
      method:'post',
      data:parameter
  })
}

//获取班级学生列表
export function getClassStudentList (parameter){
  return request({
    url:'/api/student-roster/getStudentListBySchoolClass',
    method:'post',
    data:parameter
})
}

