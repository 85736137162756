<!-- 花名册列表 -->
<template>
<div class="table">
  <el-table
    ref="multipleTable"
    :data="tableData"
    :highlight-current-row="true"
    tooltip-effect="dark"
    @row-click="clickRow"
    style="width:100%;color:#858585;"
    :header-cell-style="tableHeaderCellStyle">
    <!-- 表头开始 -->
    <el-table-column
      label="年级"
      align="center">
      <template slot-scope="scope">{{ scope.row.studentGradeOrClass }}年级</template>
    </el-table-column>
    
  </el-table>
  <!-- 分页 -->
  <div class="page">
  <!-- left -->
  <span class="pageLeft">共 {{count}} 条记录 第 {{currentPage}} / {{tatalPage}}页</span>
  <!-- right -->
  <el-pagination
      @size-change="SizeChange"
      background
      @current-change="CurrentChange"
      @prev-click="prevClick"
      @next-click="nextClick"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="onlyPage"
      :pager-count="5"
      layout="sizes, prev, pager, next, jumper"
      :total="count">
    </el-pagination>
  </div>
</div>
</template>

<script>
import {getGradeList} from '@/api/roster.js'
  export default {
    data() {
      return {
        orgId:null,
        orgName:null,
        tableData: [],//表格数据
        // 分页相关
        currentPage:1,// 当前页面,默认1
        tatalPage:0,//总页数,为加载时先为0，页面首次加载后去请求即可
        count:0,//总的条数记录
        onlyPage:10,// 当前页面可以展示多少条数据,默认10 

      }
    },
      created(){
        if(this.$route.query){
          this.orgId = this.$route.query.id;
          this.orgName = this.$route.query.orgName;
           this.getData(1,40,this.$route.query.id);
        }
    },

    methods: {
      //表头样式回调
      tableHeaderCellStyle(){
          return "color:#fff;background-color:#00CCCC";
        },
      // getData
      getData(currentPage,onlyPage,id){
              const rLoading = this.openLoading();// 开始loading
              getGradeList({
              orgId:id,
              currentPage: currentPage,
              pageSize: onlyPage
            }).then((res)=>{
              if(res.data.status == 200){
                
                 this.tableData = res.data.data.records;
                 this.tatalPage =parseInt(res.data.data.size);
                 this.count =parseInt(res.data.data.total);
                 rLoading.close() ;// 关闭loading
              }else{
                this.$message.error(res.data.message);
              }
            }).catch(res=>{
               this.$message.error(res);
            })
      },
      // 分页相关
      SizeChange(val){
        // pageSize 改变时会触发  回调参数 每页条数
       // console.log("每页多少条",val);
        this.onlyPage = val;
        this.getData(this.currentPage,this.onlyPage,this.orgId);//更新数据
      },
      CurrentChange(val){
        // currentPage 改变时会触发  回调参数 当前页
       // console.log("当前页面",val);
        this.currentPage = val;
        this.getData(this.currentPage,this.onlyPage,this.orgId);//更新数据
      },
      prevClick(val){
        // 上一页
        // console.log("上一页的页数是",val);
        this.currentPage = val ;
        this.getData(this.currentPage,this.onlyPage,this.orgId);//更新数据

      },
      nextClick(val){
        // 下一页
        // console.log("下一页的页数是",val);
        this.currentPage = val ;
        this.getData(this.currentPage,this.onlyPage,this.orgId);//更新数据

      },
      //click ROW
      clickRow(e){
        //console.log("点击参数",e)
        this.$router.push({
          path:'/roster/gradeList/classList',
          query:{
            orgName:this.orgName,
            orgId:this.orgId,
            grade:e.studentGradeOrClass
          }
        })
      }
    },
    computed:{
    
    }
  }
</script>
<style scoped>
.table{
    margin-top: .1563rem;
   
}
.el-button{
    color: #00A3A3 !important;
}
.action{
    display: flex;
    flex-direction: column;
}
.actionP{
  cursor: pointer;
  color: #00CCCC;
}
.page{
  display: flex;
  margin-top: .3125rem;
  justify-content: space-between;
  align-items: center;
}
.pageLeft{
  color: #858585;
  font-size: .1094rem;
  line-height: .1875rem;
}
.el-pagination{
  color: #858585 !important;
  font-size: .1094rem !important;
  line-height: .1875rem !important;
}
</style>