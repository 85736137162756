<!-- 学生列表 -->
<!-- 学校花名册 -->
<template>
     <div class="userList">
        <!-- top -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item >花名册管理</el-breadcrumb-item>
            <el-breadcrumb-item @click.native="goback1">学校列表</el-breadcrumb-item>
            <el-breadcrumb-item>年级列表</el-breadcrumb-item>
        </el-breadcrumb>
       
        <div class="content">
            <!-- tableTop -->
            <div class="tableTop">
                <div class="tableTop_left"></div>
            </div>
            <RosterClaTable/>
        </div>
     </div>
</template>
<script>
import RosterClaTable from '@/components/table/rosterGradeTable.vue'
export default {
    beforeRouteLeave(to,from,next){
        if(to.name === 'Roster'){
            to.meta.keepAlive = true;
        }
        next();
    },
    components:{
        RosterClaTable,
    },
    data(){
        return{

        }
    },
    methods:{
        //面包屑
        goback1(){
            this.$router.go(-1);
        },
    }
}
</script>
<style scoped lang="stylus">

//面包屑样式
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }

/deep/.el-input__icon {
    line-height: 0 !important;
}

/deep/.el-icon-date:before{
    line-height: 0.2rem !important;
    font-size:0.11rem
}

/deep/.el-input__inner{
    height: .30rem !important;
    font-size: .11rem !important
  }
/deep/.el-input-group__append{
    border-block-color:#00CCCC !important;
    color: #fff !important;
    background-color: #00CCCC !important;
    border: 0 !important;
}
/deep/.el-button{
    padding: 0px 0 0 4px !important;
    text-align: center !important;
    color: #fff !important;
    font-size: 0.11rem !important;
  }
/deep/.el-date-editor .el-range-input{
    font-size: .11rem !important
}

// 正常嵌套样式写法
.content
    overflow hidden
    position: relative
    margin-top: 16px
    padding: 20px
    background-color: #FFFFFF
  
    .tableTop 
        display: flex
        justify-content: space-between
        align-items: center
        margin-top: 20px
        .tableTop_left
            display: flex

</style>